<template>
  <div id="app">
    <Header @change-bar="changeBar" v-bind:selectBar="tabbar" />
    <div class="content super-content">
<!--      <img alt="Vue logo" src="./assets/logo.png">-->
<!--      <Home v-if="tabbar == 'index'" msg="screenheight" />-->
<!--      <product v-if="tabbar == 'product'" />-->
<!--      <join v-if="tabbar == 'join'" />-->
<!--      <service v-if="tabbar == 'service'" />-->
      <Home ref='home'  />
      <product ref="product" />
      <service ref="service" />
      <join ref="join" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Home from './components/Home.vue'
import Header from "@/components/common/Header";
import Footer from "@/components/common/Footer";
import Product from "@/components/Product";
import Join from "@/components/Join";
import Service from "@/components/Service"

export default {
  name: 'App',
  components: {
    Footer,
    Header,
    Home,
    Product,
    Join,
    Service
  },
  data() {
    return {
      tabbar: 'index',
      screenheight: 0
    }
  },
  created(){

  },
  mounted(){
    this.getWindow()
    // window.addEventListener('mousewheel',this.handleScroll);
  },
  methods: {
    changeBar(e){
      this.tabbar = e
    },
    handleScroll() {
      console.log('滚动高度', window.pageYOffset)
      console.log('距离顶部高度', this.$refs.home.$el.offsetTop)
    },
    // getWindow(){
    //     console.log(document.documentElement.clientHeight)
    //     console.log(document.documentElement.clientWidth)
    //     console.log(this.$refs.home.$el.offsetTop)
    //     console.log(this.$refs.home.$el.clientHeight)
    //     console.log(this.$refs.home.$el.scrollHeight)
    //
    //     console.log(this.$refs)
    //     console.log(this.$refs.product.$el.offsetTop)
    //     console.log(this.$refs.service.$el.offsetTop)
    //     console.log(this.$refs.join.$el.offsetTop)
    //
    // }
  }
}
</script>

<style>
/*@import '~@/assets/css/index.css';*/
@import url('assets/css/index.css');
@import url('assets/css/layout.css');
@import url('assets/css/m_page.css');
@import url('assets/css/pc_page.css');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
  height: 100%;
}
.content{
  padding-top: 36px;
}
@media screen and (min-width: 780px){
  .content{
    padding-top: 68px !important;
  }
}


</style>
