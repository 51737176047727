import Vue from 'vue'
import App from './App.vue'
import 'jquery/dist/jquery.min.js'
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
import { getWindow } from './assets/js/common.js'

Vue.prototype.$getWindow = getWindow

Vue.config.productionTip = false

Vue.prototype.pageShareC = function(shareUrl, shareTitle="超充科技-超充十分钟电量八分满"){
  // var jweixin = require('assets/jweixin-module/lib/index.js')
  uni.request({
    url: this.baseURL + '/sys/wxconfig',
    method: 'POST',
    data: {
      url: shareUrl
    },
    success(res) {
      let data = res.data
      let configData = data.result
      if(data.success){
        jweixin.config({
          // debug: true,
          appId: configData.appId,
          timestamp: configData.timestamp,
          nonceStr: configData.nonceStr,
          signature: configData.signature,
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData", 'onMenuShareAppMessage', 'onMenuShareTimeline']
        })

        jweixin.ready(function(){
          jweixin.updateAppMessageShareData({
            title: shareTitle, // 分享标题
            desc: "超充科技系汽车智能化时代的超级快充解决方案提供商，通过对电动汽车超大功率充电技术的研发和配套设备的生产，提供充电设备升级", // 分享描述
            link: "", // 分享链接
            imgUrl: 'https://web.tusparkgd.com/static/logo.png', // 分享图标
            success:function(res){
              console.log("res: " + res)
            },
            fail: function(err){
              console.log("err: " + err)
            }
          });
          jweixin.updateTimelineShareData({
            title: shareTitle, // 分享标题
            desc: "超充科技系汽车智能化时代的超级快充解决方案提供商，通过对电动汽车超大功率充电技术的研发和配套设备的生产，提供充电设备升级", // 分享描述
            link: "", // 分享链接
            imgUrl: 'https://web.tusparkgd.com/static/logo.png', // 分享图标
            success:function(res){
              console.log("res: " + res)
            },
            fail: function(err){
              console.log("err: " + err)
            }
          }),
          jweixin.onMenuShareAppMessage({
            title: shareTitle, // 分享标题
            desc: '超充科技系汽车智能化时代的超级快充解决方案提供商，通过对电动汽车超大功率充电技术的研发和配套设备的生产，提供充电设备升级', // 分享描述
            link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://web.tusparkgd.com/static/logo.png', // 分享图标
            type: '', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success: function (es) {
              // 用户点击了分享后执行的回调函数
              console.log("res: " + res)
            },
            fail: function(err){
              console.log("err: " + err)
            }
          })
        })
      }
    },
    fail: (err) => {
      console.log(err)
    }
  })
}


new Vue({
  render: h => h(App),
}).$mount('#app')
