<template>
  <div id="footer">

    <footer class="footer-content">
        <div class="content-content row">
            <div class="col-md-4 company-ba">
                <div class=" ba-item">
                    <div class="company-info text-left ">地址：北京市海淀区上地信息路2号D座7层</div>
                </div>
                <div class="  ba-item">
                  <div class="company-info  text-left">邮箱：wanglufei@sscharge.com</div>
                </div>
                <div class="  ba-item">
                  <div class="company-info  text-left">网址：www.sscharge.com</div>
                </div>
                <div class=" ba-item">
                  <div class="company-info text-left ">电话：18515466556</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="ba-item"><div class="com-item text-left"><span>北京超充科技有限公司      电话：18515466556</span></div></div>
                <div class="ba-item"><div class="com-item text-left"><span>四川超充科技有限公司      电话：13990900852</span></div></div>
                <div class="ba-item"><div class="com-item text-left  close-bottom"><span>武汉超充科技有限公司      电话：15072310818</span></div></div>
            </div>

            <div class="col-md-3 company-social ">
                <img class="qrcode" src="/icon/weixingongzhonghao.jpg">
<!--                <div class="font-12" style="margin-top: 8px">扫码关注</div>-->
            </div>
        </div>
        <div class="beian">
          <a class="font-color-000" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP 备2021015169号</a>
        </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer-content{
  width: 100%;
  background: #F3F4F5;;
  padding-left: 5%;
  padding-right: 5%;
}
.content-content{
  padding-top: 50px;
  padding-bottom: 20px;
  width: 100%;
}
.content-content.row{
  margin-right: 0;
  margin-left: 0;
}
.ba-item{
  padding: 10px 0;
}
.com-item span{
  padding: 0 8px 10px;
  border-bottom: 1px solid #D7D7D7;
}
.close-bottom span{
  border-bottom: 0px solid #D7D7D7;
  margin-bottom: 20px;
}
.qrcode{
  height: 120px;
  width: 120px;
}
.beian{
  padding-top: 10px;
  padding-bottom: 20px;
}
.beian a{
  color: #000000 !important;
}
</style>