<template>
  <div id="home">
      <div class="font-color-white item-one">
          <div class="pc-modal">
            <div class="symbol-content">
                          <div class="font-50 font-bold" style="font-size: 7rem; font-weight: 900">Super Smart</div>
                          <div class="font-50 font-bold" style="font-size: 7rem; font-weight: 900">Charge</div>
                          <div class="font-22" style="margin-top: 30px">电动汽车超级快充解决方案提供商</div>
            </div>
            <img class="oneBg" :style="{maxHeight: maxheight + 'px', width:'100%'}" src="bgImg/pc_bg-one.png" alt="电动汽车超级快充解决方案提供商" >
          </div>
          <div class="m-modal">
              <img class="oneBg" :style="{maxHeight: maxheight + 'px', width:'100%'}" src="bgImg/bg-one.png" alt="电动汽车超级快充解决方案提供商" >
          </div>

      </div>
      <div class="item-two about-us index-padding">
          <div> <h3 class="font-color-395">关于超充科技</h3> </div>
          <div class="company-intro  font-14">
              <div>北京超充科技有限公司于2021年4月在北京成立，目前已先后完成智盛领航基金和中科云道基金的种子轮及天使轮融资</div>
              <div>公司定位于成为汽车智能化时代的超级快充解决方案提供商，通过对电动汽车超大功率充电技术的研发和配套设备的生产，提供充电设备升级，充电站建设、智能运营及物联网数据增值服务</div>
              <div>创始经营团队由知名投资集团高管和连续创业者组成，拥有丰富的投资和互联网创业经验，研发团队由北京交通大学、东南大学、北京航空航天大学、中科院电交所、西安交通大学等知名高校的教授博导领衔，以原任职中国能建、优科新能源、美国苹果、百度等知名高科技企业员工为核心骨干</div>
              <div>公司在大功率充电技术、电网互动技术、V2G双向充放电技术、电池安全检测技术、能量存储技术、边缘计算与存储技术、无线通讯技术、PAAS技术等领域做深入研发及应用，已经储备了核心专利技术超100项</div>
          </div>
      </div>
      <div class="item-three">
          <img src="bgImg/image1.png" :style="{maxHeight: maxheight + 'px', width:'100%'}">
      </div>
      <div class="item-four develop index-padding">
          <div><h3 class="font-color-395">研发方向</h3></div>
          <div class="develop-content row">
              <div class="col-md-4 develop-item">
                  <img class="develop-icon" src="icon/develop1.png">
                  <h4>储备核心专利技术</h4>
                  <span class="font-color-5D5 font-14">和北京交通大学、东南大学、北京航空航天大学、中科院电工所、西安交通大学等合作储备专利技术100+</span>
              </div>
              <div class="col-md-4 develop-item">
                  <img class="develop-icon" src="icon/develop2.png">
                  <h4>硬件研发方向</h4>
                  <span class="font-color-5D5 font-14">大功率电源模块、液冷充电枪线、超充充电控制板、车载大功率插座</span>
              </div>
              <div class="col-md-4 develop-item">
                  <img class="develop-icon" src="icon/develop3.png">
                  <h4>软件研发方向</h4>
                  <span class="font-color-5D5 font-14">大功率充电技术、V2G技术、电网技术、储能技术、电池监测技术、边缘计算与存储技术、无线通讯技术、PAAS技术</span>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      maxheight: 'auto'
    }
  },

  created() {
    this.maxheight= (window.innerHeight - 70)
    console.log(this.maxheight)
  },
  props: {
    // msg: String,
    // screenHeight: Number
  },

  mounted(){

  },
  methods: {
    getClintHeight(){
      var clientHeight=0;
      if(document.body.clientHeight&&document.documentElement.clientHeight)
      {
        clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }
      else
      {
        clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }
      console.log(clientHeight)
      return clientHeight
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#home{
  background-color: #EEF0F3;
}
.item-one{
  position: relative;
  width: 100%;
}
.item-one .symbol-content{
  position: absolute;
  width: 100%;
  top: 38%;
  text-align: center;
}
.company-intro{
  line-height: 30px;
  padding: 20px 0;
  text-align: left;
}

.develop-item{
  padding-top: 20px;
  padding-bottom: 20px;
}
.pc-modal{
  display: none;
}
.m-modal{
  display: block;
}

.develop-item span{
  margin-top: 20px;
  line-height: 25px;
}
.develop-icon{
  height: 50px;
  width: 50px;
  margin-bottom: 20px;
}
.about-us{
  padding-top: 30px;
  padding-bottom: 30px;
}
.develop{
  padding-top: 30px;
}
</style>
