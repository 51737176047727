<template>
    <div id="join"  class="hidden-xs hidden-sm">
        <div class="font-color-white item-one bg-white">
          <div class="pc-modal">
            <div class="symbol-content">
              <div class="font-50 font-bold" style="font-size: 70px; font-weight: 900">创造你想要的未来</div>
            </div>
            <img src="bgImg/pc_bg-four.png" :style="{maxHeight: maxheight + 'px', width:'100%'}" alt="创造你想要的未来">
          </div>
          <div class="m-modal">
            <img src="bgImg/bg-four.png" :style="{maxHeight: maxheight + 'px', width:'100%'}" alt="创造你想要的未来">
          </div>

        </div>
        <div class="join-item">
            <div class="row index-padding">
                <div class="col-md-6 item">
                    <h4 class="font-color-395 item-title">真诚</h4>
                    <div class="item-desc font-14">我们重视每一个行为的真实性、一致性和透明性，坦诚而真挚地同利益相关方分享我们的历程。</div>
                </div>
                <div class="col-md-6 item">
                    <h4 class="font-color-395 item-title">关爱</h4>
                    <div class="item-desc font-14">我们重视每一个行为的真实性、一致性和透明性，坦诚而真挚地同利益相关方分享我们的历程。</div>
                </div>
            </div>
            <div class="row index-padding">
                <div class="col-md-6 item ">
                    <h4 class="font-color-395 item-title">远见</h4>
                    <div class="item-desc font-14">敢于探索未知的道路——哪里能为用户创造价值，我们就在哪里实现颠覆。同时，我们睿智地考虑问题的多元性，以卓尔不凡的思维和行动方式迎接挑战。</div>
                </div>
                <div class="col-md-6 item">
                    <h4 class="font-color-395 item-title">相信</h4>
                    <div class="item-desc font-14">我们将一切付诸行动——只做自己相信的事，并通过愿景的实现来建立信任。在围绕明确目标快速行动的同时，我们也积极预测用户的需求，为我们创造新的商业机遇。</div>
                </div>
            </div>
        </div>
        <div class="itme-five partner bg-white">
            <div> <h2 class="font-color-395">意向合作</h2> </div>
            <div class="partner-content">
              <div class="swiperbtn">
                <div class="pre-btn" @click="preItem"></div>
                <div class="next-btn" @click="nextItem"></div>
              </div>
              <div class="partner-list">
                    <div class="row">
                        <div class="col-md-2 col-xs-6"> <div class="partner-item partner1"></div> </div>
                        <div class="col-md-2 col-xs-6"> <div class="partner-item partner2"></div> </div>
                        <div class="col-md-2 col-xs-6"> <div class="partner-item partner3"></div> </div>
                        <div class="col-md-2 col-xs-6"> <div class="partner-item partner4"></div> </div>
                        <div class="col-md-2 col-xs-6"> <div class="partner-item partner5"></div> </div>
                        <div class="col-md-2 col-xs-6"> <div class="partner-item partner6"></div> </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2 col-xs-6"> <div class="partner-item partner7"></div> </div>
                      <div class="col-md-2 col-xs-6"> <div class="partner-item partner8"></div> </div>
                      <div class="col-md-2 col-xs-6"> <div class="partner-item partner9"></div> </div>
                      <div class="col-md-2 col-xs-6"> <div class="partner-item partner10"></div> </div>
                      <div class="col-md-2 col-xs-6"> <div class="partner-item partner11"></div> </div>
                      <div class="col-md-2 col-xs-6"> <div class="partner-item partner12"></div> </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2 col-xs-6"> <div class="partner-item partner13"></div> </div>
                      <div class="col-md-2 col-xs-6"> <div class="partner-item partner14"></div> </div>
                      <div class="col-md-2 col-xs-6"> <div class="partner-item partner15"></div> </div>
                      <div class="col-md-2 col-xs-6"> <div class="partner-item partner16"></div> </div>
                      <div class="col-md-2 col-xs-6"> <div class="partner-item partner17"></div> </div>
                      <div class="col-md-2 col-xs-6"> <div class="partner-item partner18"></div> </div>
                    </div>
              </div>
              <div class="partner-list2">
              <div class="row">
                <div class="col-md-2 col-xs-6"> <div class="partner-item partner1"></div> </div>
                <div class="col-md-2 col-xs-6"> <div class="partner-item partner2"></div> </div>
                <div class="col-md-2 col-xs-6"> <div class="partner-item partner3"></div> </div>
                <div class="col-md-2 col-xs-6"> <div class="partner-item partner4"></div> </div>
                <div class="col-md-2 "> </div>
                <div class="col-md-2 "> </div>
              </div>
              <div class="row">
                <div class="col-md-2 col-xs-6"> <div class="partner-item partner7"></div> </div>
                <div class="col-md-2 col-xs-6"> <div class="partner-item partner8"></div> </div>
                <div class="col-md-2 col-xs-6"> <div class="partner-item partner9"></div> </div>
                <div class="col-md-2 col-xs-6"> <div class="partner-item partner10"></div> </div>
                <div class="col-md-2 ">  </div>
                <div class="col-md-2 ">  </div>
              </div>
              <div class="row">
                <div class="col-md-2 col-xs-6"> <div class="partner-item partner13"></div> </div>
                <div class="col-md-2 col-xs-6"> <div class="partner-item partner14"></div> </div>
                <div class="col-md-2 col-xs-6"> <div class="partner-item partner15"></div> </div>
                <div class="col-md-2 ">  </div>
                <div class="col-md-2 ">  </div>
                <div class="col-md-2 ">  </div>
              </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "Join",
  data() {
    return {
      maxheight: 'auto',
      partner: [
        {
          companyurl: 'https://www.crrcgc.cc',
          companylogo: '/partner/crrcgc.png'
        },
        {
          companyurl: 'http://www.potevio.com',
          companylogo: '/partner/ptlogo.png'
        },
        {
          companyurl: 'http://www.sgcc.com.cn',
          companylogo: '/partner/sgcc.png'
        },
        {
          companyurl: 'http://www.bjbus.com/home/index.php',
          companylogo: '/partner/bjbus.png'
        },
        {
          companyurl: 'http://www.besg.com.cn',
          companylogo: '/partner/besg.png'
        },
        {
          companyurl: 'https://www.vw.com.cn',
          companylogo: '/partner/dazhong.png'
        },
        {
          companyurl: 'https://www.jac.com.cn',
          companylogo: '/partner/logonow.png'
        },
        {
          companyurl: 'https://www.shenzhou-gaotie.com/gtszgt/index.htm',
          companylogo: '/partner/shenzhou.png'
        },
        {
          companyurl: 'http://www.baicmotor.com',
          companylogo: '/partner/baicmotor.png'
        },
        {
          companyurl: 'http://www.keytop.com.cn',
          companylogo: '/partner/keytop.png'
        },
        {
          companyurl: 'http://www.longruisanyou.com',
          companylogo: '/partner/longruisanyou.png'
        },
        {
          companyurl: 'http://www.nextcharge.com.cn/index.html',
          companylogo: '/partner/nextcharge.png'
        },
        {
          companyurl: 'http://www.nnlighting.com/',
          companylogo: '/partner/nnlighting.png'
        },
        {
          companyurl: 'https://www.bit.edu.cn/',
          companylogo: '/partner/bit.png'
        },
        {
          companyurl: 'http://www.hzepower.com.cn',
          companylogo: '/partner/epower.png'
        },
        {
          companyurl: 'https://www.citicbank.com',
          companylogo: '/partner/citicbank.png'
        },
        {
          companyurl: 'https://www.bjtu.edu.cn',
          companylogo: '/partner/bjtu.png'
        },
        {
          companyurl: 'http://www.sunwoda.com',
          companylogo: '/partner/swd.png'
        },
        {
          companyurl: 'https://www.cauc.edu.cn/zhv3/',
          companylogo: '/partner/cauc.png'
        },
        {
          companyurl: 'https://www.sinexcel.com',
          companylogo: '/partner/dazhong.png'
        },
        {
          companyurl: 'http://www.accuenergy.com.cn',
          companylogo: '/partner/accuenergy.png'
        },
        {
          companyurl: 'http://www.cpic.com.cn',
          companylogo: '/partner/cpic.png'
        },
        {
          companyurl: 'http://www.sheenpower.com',
          companylogo: '/partner/sheenpower.png'
        },
      ],
      partnerlist: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
    }
  },

  created() {
    this.maxheight= (window.innerHeight)
  },
}
</script>

<style scoped>
.row{
  margin-right: 0 !important;
  margin-left: 0 !important;
}
#join{
  background-color: #EEF0F3;
  /*padding-top: 68px;*/
}

.item-one{
  position: relative;
  width: 100%;
}
.item-one .symbol-content{
  position: absolute;
  width: 100%;
  top: 42%;
  text-align: center;
}

.partner{
  padding: 40px 50px 20px;
}
.join-item .item{
  padding: 40px 0px 10px;
}
.item-title{
  margin-bottom: 20px;
}
.item-desc{
  line-height: 30px;
  font-width: 500;
}
.partner-content{
  padding: 30px 5%;
  position: relative;
  width: 100%;
}
.pc-modal{
  display: none;
}
.m-modal{
  display: block;
}
.partner{
  display: none;
}


/*.swiperbtn {*/
/*  width: 75%;*/
/*  position: absolute;*/
/*  top: 45%;*/
/*  transform: translateY(-50%);*/
/*}*/
/*.pre-btn{*/
/*  width: 30px;*/
/*  height: 30px;*/
/*  background: url('/img/left-right.png') no-repeat left center;*/
/*  float: left;*/
/*  margin-left: 10px;*/
/*  cursor: pointer;*/
/*}*/
/*.next-btn{*/
/*  width: 30px;*/
/*  height: 30px;*/
/*  background: url('/img/left-right.png') no-repeat right center;*/
/*  float: right;*/
/*  margin-right: 10px;*/
/*  cursor: pointer;*/
/*}*/



.partner-list .partner-item{
  background: url('/partner/bg-partner.png');
  background-repeat: no-repeat;
  position: relative;
  background-attachmentt: fixed;
  height: 80px;
  margin: 0 auto;
}
.partner-list .partner-item.partner1{
  background-position: -3% -15%;
  width: 135px;
}
.partner-list .partner-item.partner2{
  background-position: 18% -15%;
  width: 135px;
}
.partner-list .partner-item.partner3{
  background-position: 37% -15%;
  width: 135px;
}
.partner-list .partner-item.partner4{
  background-position: 54% -15%;
  width: 135px;
}
.partner-list .partner-item.partner5{
  background-position: 78% -15%;
  width: 135px;
}
.partner-list .partner-item.partner6{
  background-position: 105% -15%;
  width: 135px;
}
.partner-list .partner-item.partner7{
  background-position: -3% 56%;
  width: 135px;
}
.partner-list .partner-item.partner8{
  background-position: 18% 56%;
  width: 135px;
}
.partner-list .partner-item.partner9{
  background-position: 37% 56%;
  width: 135px;
}
.partner-list .partner-item.partner10{
  background-position: 54% 56%;
  width: 135px;
}
.partner-list .partner-item.partner11{
  background-position: 78% 56%;
  width: 135px;
}
.partner-list .partner-item.partner12{
  background-position: 105% 56%;
  width: 135px;
}
.partner-list .partner-item.partner13{
  background-position: -3% 117%;
  width: 135px;
}
.partner-list .partner-item.partner14{
  background-position: 18% 117%;
  width: 135px;
}
.partner-list .partner-item.partner15{
  background-position: 37% 117%;
  width: 135px;
}
.partner-list .partner-item.partner16{
  background-position: 54% 117%;
  width: 135px;
}
.partner-list .partner-item.partner17{
  background-position: 78% 117%;
  width: 135px;
}
.partner-list .partner-item.partner18{
  background-position: 105% 117%;
  width: 135px;
}
.partner-list2 .partner-item{
  background: url('/partner/bg-partner2.png');
  background-repeat: no-repeat;
  position: relative;
  background-attachmentt: fixed;
  height: 100px;
  margin: 0 auto;
}
.partner-list2 .partner-item.partner1{
  background-position: -3% -15%;
  width: 135px;
}
.partner-list2 .partner-item.partner2{
  background-position: 36% -15%;
  width: 135px;
}
.partner-list2 .partner-item.partner3{
  background-position: 62% -15%;
  width: 135px;
}
.partner-list2 .partner-item.partner4{
  background-position: 90% -15%;
  width: 135px;
}

.partner-list2 .partner-item.partner7{
  background-position: -3% 56%;
  width: 135px;
}
.partner-list2 .partner-item.partner8{
  background-position: 36% 56%;
  width: 135px;
}
.partner-list2 .partner-item.partner9{
  background-position: 62% 56%;
  width: 135px;
}
.partner-list2 .partner-item.partner10{
  background-position: 100% 56%;
  width: 168px;
}

.partner-list2 .partner-item.partner13{
  background-position: -3% 117%;
  width: 135px;
}
.partner-list2 .partner-item.partner14{
  background-position: 36% 117%;
  width: 135px;
}
.partner-list2 .partner-item.partner15{
  background-position: 62% 117%;
  width: 135px;
}

</style>