<template>
  <div id="product">
      <div class="font-color-white item-one bg-white">
        <div class="pc-modal">
          <div class="symbol-content">
              <div class="font-50 font-bold" style="font-size: 70px; font-weight: 900">Ten Minutes </div>
              <div class="font-22" style="margin-top: 30px">充电十分钟，电量八分满</div>
          </div>
          <img src="bgImg/pc_bg-two.png" :style="{maxHeight: maxheight + 'px', width:'100%'}" alt="充电十分钟，电量八分满">
        </div>
        <div class="m-modal">
          <img src="bgImg/bg-two.png" :style="{maxHeight: maxheight + 'px', width:'100%'}" alt="充电十分钟，电量八分满">
        </div>

      </div>
    <div class="item-two pc-page charging-pile">
        <div> <h3 class="font-color-395" style="padding-bottom: 40px">SiC超大功率充电桩</h3> </div>
        <div class="charging-detail font-14 ">
            <div class="detail-one row index-padding">
                <div class="col-md-5 text-left charging-title">
                    <div style="margin-bottom: 10px;">
                        <h4 class="font-color-395">一体式直流快充双枪充电桩</h4>
                        <div class="font-color-5D5 ">最大输出120kw/180kw，最大电流200A/250A</div>
                    </div>
                    <div class="font-16 advantage-item">
                        <div class="font-color-395 font-blod">适用场景</div>
                        <div class="font-color-5D5">应用于乘用车、物流车、牵引车直流快速充电；</div>
                        <div class="font-color-5D5">适用于充电运营商、公交公司、出租网约车、房地产开发商等充电运营。</div>
                    </div>
                    <div class="font-16 advantage-item">
                      <div class="font-color-395 font-blod">安全美观</div>
                      <div class="font-color-5D5">专利外观设计，出色的防护性能，优异的风道设计，散热能力强。</div>
                    </div>
                    <div class="font-16 advantage-item">
                      <div class="font-color-395 font-blod">节能高效</div>
                      <div class="font-color-5D5">采用新一代SiC功率半导体器件，能量转换效率更高，耐高温，可靠性强。</div>
                    </div>
                    <div class="font-16 advantage-item">
                      <div class="font-color-395 font-blod">场景灵活</div>
                      <div class="font-color-5D5">单双枪，多种配置可选，兼容性强，适用于多种运营场景。</div>
                    </div>

                </div>
                <div class="col-md-5 text-left"><img src="bgImg/product/front.png" style="max-height: 600px; max-width: 100%"></div>
            </div>
            <div class="detail-two bg-white row index-padding">
              <div class="col-md-5">
                  <img style="max-height: 600px; max-width: 100%;" src="bgImg/product/side.png">
              </div>
              <div class="col-md-5 text-left charging-title2" >
                <div style="margin-bottom: 10px;padding-top: 2px">
                  <h4 class="font-color-395">分体式直流超充一拖二充电桩</h4>
                  <div class="font-color-5D5 ">最大输出360kw，最大电流600A</div>
                </div>
                <div class="font-16 advantage-item ">
                  <div class="font-color-395 font-blod">适用场景</div>
                  <div class="font-color-5D5">应用于乘用车、物流车、牵引车超级直流快速充电</div>
                  <div class="font-color-5D5">适用于充电运营商、物流园区、公交公司等大规模充电场站充电运营。</div>
                </div>
                <div class="font-16 advantage-item">
                  <div class="font-color-395 font-blod">节能高效</div>
                  <div class="font-color-5D5">采用新一代SiC功率半导体器件，能量转换效率更高，耐高温，可靠性强。</div>
                </div>
                <div class="font-16 advantage-item">
                  <div class="font-color-395 font-blod">智能分配</div>
                  <div class="font-color-5D5">实现终端机充电电流按需分配，提高功率模块利用率。</div>
                </div>
                <div class="font-16 advantage-item">
                  <div class="font-color-395 font-blod">配置灵活</div>
                  <div class="font-color-5D5">采用模块化设计，充电终端数量及功率根据客户需求定制。</div>
                </div>
              </div>
            </div>
        </div>
    </div>
    <div class="item-two m-page bg-white charging-pile">
      <div> <h3 class="font-color-395 bg-white" style="margin-bottom: 40px">SiC超大功率充电桩</h3> </div>
      <div class="charging-detail font-14 ">
        <div class="detail-one bg-white index-padding">
          <div class=" text-left charging-title">
            <div style="margin-bottom: 10px;">
              <h4 class="font-color-395">一体式直流快充双枪充电桩</h4>
              <div class="font-color-5D5 ">最大输出120kw/180kw，最大电流200A/250A</div>
            </div>
            <div class=""><img src="bgImg/product/front.png" style="max-height: 600px; max-width: 80%;margin: 10px 0"></div>
            <div class="font-14 advantage-item">
              <div class="font-color-395 font-blod">适用场景</div>
              <div class="font-color-5D5">应用于乘用车、物流车、牵引车直流快速充电；</div>
              <div class="font-color-5D5">适用于充电运营商、公交公司、出租网约车、房地产开发商等充电运营</div>
            </div>
            <div class="font-14 advantage-item">
              <div class="font-color-395 font-blod">安全美观</div>
              <div class="font-color-5D5">专利外观设计，出色的防护性能，优异的风道设计，散热能力强</div>
            </div>
            <div class="font-14 advantage-item">
              <div class="font-color-395 font-blod">节能高效</div>
              <div class="font-color-5D5">采用新一代SiC功率半导体器件，能量转换效率更高，耐高温，可靠性强</div>
            </div>
            <div class="font-14 advantage-item">
              <div class="font-color-395 font-blod">场景灵活</div>
              <div class="font-color-5D5">单双枪，多种配置可选，兼容性强，适用于多种运营场景</div>
            </div>
          </div>
        </div>
        <div class="detail-two index-padding">
          <div class="text-left" style="margin-bottom: 10px;">
            <h4 class="font-color-395">分体式直流超充液冷双枪充电桩</h4>
            <div class="font-color-5D5 ">最大输出360kw，最大电流600A</div>
          </div>
          <div class="text-left">
            <img style="max-height: 300px; max-width: 80%;margin: 10px 0 10px 30%" src="bgImg/product/side.png">
          </div>
          <div class=" text-left charging-title2" >
            <div class="font-14 advantage-item ">
              <div class="font-color-395 font-blod">适用场景</div>
              <div class="font-color-5D5">应用于乘用车、物流车、牵引车超级直流快速充电</div>
              <div class="font-color-5D5">适用于充电运营商、物流园区、公交公司等大规模充电场站充电运营</div>
            </div>
            <div class="font-14 advantage-item">
              <div class="font-color-395 font-blod">节能高效</div>
              <div class="font-color-5D5">采用新一代SiC功率半导体器件，能量转换效率更高，耐高温，可靠性强</div>
            </div>
            <div class="font-14 advantage-item">
              <div class="font-color-395 font-blod">智能分配</div>
              <div class="font-color-5D5">实现终端机充电电流按需分配，提高功率模块利用率</div>
            </div>
            <div class="font-14 advantage-item">
              <div class="font-color-395 font-blod">配置灵活</div>
              <div class="font-color-5D5">采用模块化设计，充电终端数量及功率根据客户需求定制</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-three">
<!--      <div class="item-two">-->
        <div class="soft-sys index-padding">
          <div><h3 class="font-color-white">软件系统/充电服务程序</h3></div>
          <div class="font-color-white font-14">移动端充电微信小程序/APP应用</div>
          <div> <img class="app-bg" src="bgImg/service/app-ui.png" style="max-width: 100%;"> </div>
        </div>
<!--      </div>-->
    </div>
      <div class="item-four operate index-padding bg-white">
          <div><h3 class="font-color-395">运营服务系统</h3></div>
          <div class="font-14 operate-desc">通过云端运营平台实现远程管理、自动异常诊断、多级别异常警告、无人值守自动管理模式等与充电设备实现实时数据传输、信息互联及数据统计分析、采取远程OTA升级方式等</div>
          <div class="develop-content row">
              <div class="col-md-4 develop-item">
                  <img class="operate-icon" src="icon/oprate1.png">
                  <h4>互联网云平台</h4>
                  <span class="font-color-5D5 font-14">充电运营平台、充电监控平台、车桩监控平台、充电桩运维平台、电站能量管理平台等</span>
              </div>
              <div class="col-md-4 develop-item">
                  <img class="operate-icon" src="icon/oprate2.png">
                  <h4>物联网IOT平台</h4>
                  <span class="font-color-5D5 font-14">充电桩协议网关、充电站安全监控、电动车辆调度、电池安全检测、电池储能监控等</span>
              </div>
              <div class="col-md-4 develop-item">
                  <img class="operate-icon" src="icon/oprate3.png">
                  <h4>大数据分析平台</h4>
                  <span class="font-color-5D5 font-14">车桩充电过程数据、充电电网互动数据、电池监控数据、充电行为数据、驾驶行为数据等</span>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "Product",
  data() {
    return {
      maxheight: 'auto'
    }
  },

  created() {
    this.maxheight= (window.innerHeight)
  },
  methods: {
  }
}
</script>

<style scoped>

#product{
  background-color: #EEF0F3;
  font-width: 500;
  padding-top: 0px;
}
.pc-page{
  display: none;
}
.m-page{
  display: block;
}
.item-one{
  /*padding-top: 68px;*/
  position: relative;
  width: 100%;
}
.item-one .symbol-content{
  position: absolute;
  width: 100%;
  /*top: 42%;*/
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  text-align: center;
  margin: auto 0;
}
.symbol-content>div{
  margin-top: 20%;
}

.advantage-item{
    padding: 10px 0;
    line-height: 25px;
}

.operate-desc{
  padding: 20px 0;
  line-height: 30px;
}
.pc-modal{
  display: none;
}
.m-modal{
  display: block;
}

.operate-icon{
  height: 50px;
  width: 50px;
  margin-bottom: 20px;
}
.operate, .charging-pile {
  padding-top: 30px;
}
.pc-page charging-detail{
  margin-top: 80px;
}
.m-page .detail-two{
  background-color: #EEF0F3;
}
.charging-pile .detail-two{
  padding-top: 30px;
  padding-bottom: 40px;
}
.charging-pile .detail-one{
  padding-top: 15px;
  padding-bottom: 15px;
}
.detail-one, .detail-two{
  margin-left: 0 !important;
  margin-right: 0!important;
}
.develop-item{
  margin-bottom: 20px;
}

.item-three{
  margin-bottom: 100px;
}
.soft-sys{
  background-color: #FF7500;
}
.soft-sys > div{
  padding-top: 30px;
}
.app-bg{
  margin-bottom: -100px;
}
</style>