<template>
    <div id="service">
        <div class="font-color-white item-one">
          <div class="pc-modal">
            <div class="symbol-content">
                  <div class="font-50 font-bold" style="font-size: 70px; font-weight: 900">Redefine</div>
                  <div class="font-50 font-bold" style="font-size: 70px; font-weight: 900">Charging Mode</div>
                  <div class="font-22" style="margin-top: 30px">电动汽车超级快充解决方案提供商</div>
            </div>
            <img src="bgImg/pc_bg-three.png" :style="{maxHeight: maxheight + 'px', width:'100%'}" alt="电动汽车超级快充解决方案提供商">
          </div>
          <div class="m-modal">
            <img src="bgImg/bg-three.png" :style="{maxHeight: maxheight + 'px', width:'100%'}" alt="电动汽车超级快充解决方案提供商">
          </div>

        </div>
        <div class="index-padding service-plan">
            <div class="plan-item">
                <h4 class="font-color-395 item-name">大功率充电解决方案</h4>
                <div class="item-desc font-14">适用于充电设备生产商、充电运营商、公交公司、物流集团、机场服务、高速服务等</div>
            </div>
            <div class="plan-item">
                <h4 class="font-color-395 item-name">充电站储能系统解决方案</h4>
                <div class="item-desc font-14">适用于设备生产商、充电运营商、储能运营商、电力管理部门、行政管理机构等</div>
            </div>
            <img src="bgImg/service/service-method.png" style="max-width: 100%">
            <div class="plan-item">
                <h4 class="font-color-395 item-name">充电车辆一体化运营解决方案</h4>
                <div class="item-desc font-14">提供充电桩安全监控系统、电动车运行监控系统、充电站能量管理系统、充电站运维服务系统等</div>
            </div>
            <div class="plan-item">
                <h4 class="font-color-395 item-name">电池安全与性能评估技术方案</h4>
                <div class="item-desc font-14">微短路识别、衰退异常分析、严重内短路识别</div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
  name: "Service",
  data() {
    return {
      maxheight: 'auto'
    }
  },
  created() {
    this.maxheight= (window.innerHeight)
  },
}
</script>

<style scoped>
#service{
  background-color: #FFF;
  padding-top: 0px;
}
.service-plan{
  background-color: #EEF0F3;
}
.service-plan .plan-item{
  padding: 20px 0 20px;
}
.plan-item .item-name{
  margin-bottom: 30px;
}
.item-one{
  position: relative;
  width: 100%;
}
.item-one .symbol-content{
  position: absolute;
  width: 100%;
  top: 36%;
  text-align: center;
}


.pc-modal{
  display: none;
}
.m-modal{
  display: block;
}
@media screen and (min-width: 1440px){

}
</style>