<template>
  <div id="header" >
    <nav class="navbar navbar-default navbar-fixed-top" role="navigation">
      <div class="container-fluid super-content">
        <div class="navbar-header">
<!--          <button type="button" class="navbar-toggle" data-toggle="collapse"-->
<!--                  data-target="#example-navbar-collapse">-->
<!--            <img @click="openMobileMenu" class="text-left menuBtn" src="/icon/menu.png">-->
<!--          </button>-->
          <img class="img-logo" src="/images/logo.jpg" alt="超充科技" >
        </div>
        <div class="collapse navbar-collapse navbar-right" id="example-navbar-collapse">
          <ul class="nav navbar-nav">
            <li :class="selectBar == 'index' ?  'active' : ''"><a href="#" @click="navbarChange('index')" data-bar="index">首页</a></li>
            <li :class="selectBar == 'product' ?  'active' : ''"><a href="#product" @click="navbarChange('product')" data-bar="product">产品技术</a></li>
            <li :class="selectBar == 'service' ?  'active' : ''"><a href="#service" @click="navbarChange('service')" data-bar="service">解决方案</a></li>
            <li :class="selectBar == 'join' ?  'active' : ''"><a href="#join" @click="navbarChange('join')" data-bar="join">加入</a></li>
          </ul>
        </div>
      </div>
    </nav>
    <div v-if="showMobileMenu" id="mobile-menu" class="mobile-nav visible-xs visible-sm bg-white">
      <ul class="nav navbar-nav">
        <li :class="selectBar == 'index' ?  'mactive' : ''"><a href="#" @click="navbarChange('index')" data-bar="index">首页</a></li>
        <li :class="selectBar == 'product' ?  'mactive' : ''"><a href="#product" @click="navbarChange('product')" data-bar="product">产品技术</a></li>
        <li :class="selectBar == 'service' ?  'mactive' : ''"><a href="#service" @click="navbarChange('service')" data-bar="service">解决方案</a></li>
<!--        <li :class="selectBar == 'join' ?  'mactive' : ''"><a href="#join" @click="navbarChange('join')" data-bar="join">加入</a></li>-->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data(){
      return {
        showMobileMenu: false
      }
  },
  props: {
    selectBar: {
      type: String,
      default: 'index'
    },
  },
  methods: {
    navbarChange(e){
      this.$emit('change-bar', e)
      this.selectBar = e
      this.showMobileMenu = false
    },
    openMobileMenu(){
      if(this.showMobileMenu){
        this.showMobileMenu = false
      }else{
        this.showMobileMenu = true
      }
    }
  }
}
</script>

<style scoped>
.navbar{
  height: 36px;
  min-height: 36px;
  background-color: rgba(255, 255, 255, .8) !important;
  box-shadow: 0 0 14px -6px rgba(255,117,0,.2) !important;
  padding-left: 5%;
  padding-right: 5%;
  color: #000000;
}
.img-logo{
  height: 20px;
  margin-top: 7px;
}
.navbar-nav>li>a{
  padding: 8px 30px 20px;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #000 !important;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover{
  /*color: #FFFFFF;*/
  /*background: #395382;*/
  background: rgba(255, 255, 255, .1);
  border-bottom: 3px solid #FF7500;
}
#mobile-menu{
  position: fixed;
  height: 100%;
  opacity: 0.9;
  z-index: 9999999;
  margin-top: 68px;
  width: 50%;
}
#mobile-menu .navbar-nav{
  margin: 7px 0;
}

.navbar-nav>.mactive>a, #mobile-menu .nav>li>a:focus,#mobile-menu .nav>li>a:hover{
  background: #FFFFFF;
}
#mobile-menu .navbar-nav>.mactive>a{
  color: #FF7500 !important;
}
.menuBtn{

}
.navbar-toggle {
  position: relative;
  float: left;
  /* padding: 9px 10px; */
  /* margin-right: 15px; */
  margin-top: 14px;
  /* margin-bottom: 8px; */
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.navbar-default .navbar-toggle {
  border-color: #FFF;
}
</style>